const currentYear = new Date().getFullYear();

const jdonLd = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  url: 'https://enl.design',
  image: 'https://enl.design/icons/icon-512x512.png',
  headline: 'enl consulting',
  author: 'enl',
  copyrightHolder: 'enl consulting',
  copyrightYear: currentYear,
  encodings: 'UTF-8',
  keywords:
    'enl, design, branding, engineering, ui, ux, creatives, digital, innovation',
  // locationCreated: {
  //   address: {
  //     addressCountry: 'Japan',
  //     addressRegion: 'Kobe',
  //     postalCode: '6510092',
  //     streetAddress: 'Garden House 1F, 2-2-25, Ikuta Cho, Chuo-ku',
  //   },
  // },
};

export default jdonLd;
