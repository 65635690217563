import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import SEO from '../components/seo';
// JSON-LD
import jsonLd from '../json-ld';

import styles from '../styles/pages/index.module.scss';

const description =
  'enl consulting provides branding, marketing, and engineering services that helps you achieve your goals.';

const IndexPage = () => (
  <Layout>
    <Helmet
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            ...jsonLd,
            description,
          }),
        },
      ]}
    />

    <SEO
      title="Home"
      keywords={[
        `enl`,
        `design`,
        `consulting`,
        'seo',
        'software',
        'engineering',
        'marketing',
      ]}
    />
    <section className={styles.hero}>
      <div className={styles.m1} />
      <div className={styles.m2} />
      <div className={styles.m3} />
      <div className={styles.m4} />
      <div className={styles.m5} />
      <div className={styles.top}>
        <div className={styles.mask} />
        <svg id="top_gradient">
          <defs>
            <linearGradient
              id="grad1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="rotate(-23)"
            >
              <stop id="stop1" offset="0%" />
              <stop id="stop2" offset="100%" />
            </linearGradient>
          </defs>
          <rect width="100%" height="100%" />
        </svg>
      </div>
    </section>
    <section className={styles.worksWrapper}>
      <h2>works</h2>
      <div className={styles.works}>
        <figure>
          <img
            alt="Contrex Japan"
            src={require('../images/bg-contrex.jpg')}
            width="290"
          />
          <figcaption>
            <a
              title="contrexjapan.com"
              href="https://contrexjapan.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              contrexjapan.com
            </a>
          </figcaption>
        </figure>
        <figure>
          <img
            alt="Vive Port"
            src={require('../images/bg-vive.jpg')}
            width="290"
          />
          <figcaption>
            <a
              title="viveport.com"
              href="https://www.viveport.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              viveport.com
            </a>
          </figcaption>
        </figure>
        <figure>
          <img
            alt="CNYes"
            src={require('../images/bg-catchplay.jpg')}
            width="290"
          />
          <figcaption>catchplay - set-top box</figcaption>
        </figure>
        <figure>
          <img
            alt="CNYes"
            src={require('../images/bg-cnyes.jpg')}
            width="290"
          />
          <figcaption>cnyes homepage</figcaption>
        </figure>
        <figure>
          <img
            alt="CNYes"
            src={require('../images/bg-cnyes-news.jpg')}
            width="290"
          />
          <figcaption>
            <a
              title="news.cnyes.com"
              href="https://news.cnyes.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              news.cnyes.com
            </a>
          </figcaption>
        </figure>
        <figure>
          <img
            alt="Zoe.com"
            src={require('../images/bg-zoe.jpg')}
            width="290"
          />
          <figcaption>
            <a
              title="zoe.com"
              href="https://zoe.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              zoe.com
            </a>
          </figcaption>
        </figure>
      </div>
    </section>
    <section className={styles.servicesWrapper}>
      <h2>consulting + solution</h2>
      <ul className={styles.services}>
        <li>web design</li>
        <li>web performance</li>
        <li>seo</li>
        <li>branding</li>
        <li>marketing</li>
        <li>ui/ux</li>
        <li>react</li>
        <li>print</li>
      </ul>
    </section>
    <section className={styles.plansWrapper}>
      <h2>plans</h2>
      <ul className={styles.plans}>
        <li>
          <figure>
            <img
              alt="branding"
              src={require('../images/icon-branding-circle.svg')}
            />
            <figcaption>branding</figcaption>
            <p>
              logo, website, print materials to brand assets - we provide the
              complete package
            </p>
          </figure>
        </li>
        <li>
          <figure>
            <img
              alt="marketing"
              src={require('../images/icon-marketing-circle.svg')}
            />
            <figcaption>marketing</figcaption>
            <p>
              from online to offline - we provide advertising solutions that
              fits your business needs
            </p>
          </figure>
        </li>
        <li>
          <figure>
            <img
              alt="engineering"
              src={require('../images/icon-engineering-circle.svg')}
            />
            <figcaption>engineering</figcaption>
            <p>
              we craft cutting-edge, reliable, and snappy software that helps
              you achieve your goals
            </p>
          </figure>
        </li>
      </ul>
    </section>
    <section className={styles.contactWrapper}>
      <h2>contact</h2>
      <form className={styles.contact}>
        <fieldset>
          <label htmlFor="ip_company_name">company name</label>
          <input id="ip_company_name" type="text" />
        </fieldset>
        <fieldset>
          <label htmlFor="ip_name">your name</label>
          <input id="ip_name" type="text" />
        </fieldset>
        <fieldset>
          <label htmlFor="ip_email">email address</label>
          <input id="ip_email" type="text" />
        </fieldset>
        <fieldset>
          <label htmlFor="ip_message">message</label>
          <input id="ip_message" type="text" />
        </fieldset>
        <button>submit</button>
      </form>
    </section>
    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
);

export default IndexPage;
